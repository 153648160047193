html {
    user-select: none;
    height: 100%;
}

body {
    margin: 0;
    height: 100%;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.leaflet-container {
    height: 100%;
    width: 100%;
}

.dropdown {
    position: absolute;
    right: auto;
    left: 0;
    clip: auto;
    overflow: visible;
    width: auto;
    display: none;
    background: #333333;
    color: #FFFFFF;
    list-style: none;
    text-decoration: none;
    min-width: 100%;
    z-index: 99;
    padding: 0;
}

.dropdown.show {
    display: block;
}

.dropdown-submenu {
    position: absolute;
    left: 100%;
    top: 0;
}

.column, .columns {
    position: relative;
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
    float: left;
}

/* small displays */
@media only screen  {
    .show-for-small-only {
        display: inherit !important; }
    .show-for-medium, .hide-for-small-only {
        display: none !important;
    }
}

@media only screen and (min-width: 40.0625em) {
    .medium-6 {
        width: 50%;
    }
    .column, .columns {
        position: relative;
        padding-left: 0.9375rem;
        padding-right: 0.9375rem;
        float: left;
    }
    .show-for-small-only {
        display: none !important;
    }
    .show-for-medium, .hide-for-small-only {
        display: inherit !important; }
}